import type { DirectiveBinding } from 'vue'

declare global {
  interface HTMLElement {
    _trim_wheelScrollHandler: (e: WheelEvent) => void
  }
}

export type DirectionType = 'up' | 'down'

export type TrimmedScrollOptions = {
  onScroll: (direction: DirectionType) => void
  container: string
  freeScroll?: boolean
}

export const vTrimmedScroll = {
  mounted: (
    el: HTMLElement,
    binding: DirectiveBinding<TrimmedScrollOptions>
  ) => {
    const { onScroll, container, freeScroll } = binding.value

    let containerElement: HTMLElement | null = null
    try {
      containerElement = window.document.querySelector(container)
      if (!containerElement) {
        throw new Error(`Container element not found: ${container}`)
      }
    } catch (error) {
      console.error(error)
      return
    }
    el._trim_wheelScrollHandler = (e: WheelEvent) =>
      handleScroll(e, onScroll, freeScroll ?? false)

    containerElement.addEventListener('wheel', el._trim_wheelScrollHandler, {
      passive: false,
    })
  },

  updated: (
    el: HTMLElement,
    binding: DirectiveBinding<TrimmedScrollOptions>
  ) => {
    const { container, onScroll, freeScroll } = binding.value

    if (freeScroll !== binding.oldValue?.freeScroll) {
      const containerElement: HTMLElement | null =
        window.document.querySelector(container)

      if (!containerElement) {
        return
      }

      containerElement.removeEventListener('wheel', el._trim_wheelScrollHandler)

      el._trim_wheelScrollHandler = (e: WheelEvent) =>
        handleScroll(e, onScroll, freeScroll ?? false)

      containerElement.addEventListener('wheel', el._trim_wheelScrollHandler, {
        passive: false,
      })
    }
  },

  unmounted: (
    el: HTMLElement,
    binding: DirectiveBinding<TrimmedScrollOptions>
  ) => {
    const { container } = binding.value
    const containerElement: HTMLElement | null =
      window.document.querySelector(container)

    if (!containerElement) {
      return
    }

    if (el._trim_wheelScrollHandler) {
      containerElement.removeEventListener('wheel', el._trim_wheelScrollHandler)
    }
  },
}

/**
 * Calculates the average of an array of numbers.
 * @param {number[]} arr - The array of numbers.
 * @returns {number} The average of the numbers in the array.
 */
const getAverage = (arr: number[]) => {
  if (arr.length === 0) return 0
  return arr.reduce((sum, deltaY) => sum + deltaY, 0) / arr.length
}

const scrollState = {
  count: 0,
  countTimeout: null as any,
  recentDeltaYs: [] as number[],
  maxRecentDeltaYs: 8,
  isScrolling: false,
  scrollTrend: null as 'up' | 'down' | null,
  previousDeltaYAvarage: 0,
  trendTimeout: null as any,
}
const SCROLL_DEBOUNCE_TIME = 70
const TREND_TIMEOUT = 100
const SCROLL_COUNT_THRESHOLD = 5
const SCROLL_MULTIPLIER_HIGH = 1.2
const SCROLL_MULTIPLIER_LOW = 0.8

/**
 * Handles the scroll event and triggers the onScroll callback based on the scroll direction.
 * @param {WheelEvent} e - The wheel event.
 * @param {(direction: 'up' | 'down') => void} onScroll - The callback function to be called on scroll.
 * @param {boolean} freeScroll - Indicates if free scrolling is enabled.
 */
const handleScroll = (
  e: WheelEvent,
  onScroll: (direction: DirectionType) => void,
  freeScroll: boolean
) => {
  if (freeScroll === true) {
    onScroll(e.deltaY > 0 ? 'down' : 'up')
    return
  }

  e.preventDefault()
  updateScrollState(e)
  const averageDeltaY = getAverage(scrollState.recentDeltaYs)

  if (shouldTriggerScroll(averageDeltaY)) {
    triggerScrollEvent(e, onScroll, averageDeltaY)
  } else {
    adjustScrollMultiplier(averageDeltaY)
  }

  resetScrollCount()
}

/**
 * Updates the scroll state with the current wheel event.
 * @param {WheelEvent} e - The wheel event.
 */
const updateScrollState = (e: WheelEvent) => {
  clearTimeout(scrollState.countTimeout)
  scrollState.count++

  const currentDeltaY = Math.abs(e.deltaY)
  scrollState.recentDeltaYs.push(currentDeltaY)
  if (scrollState.recentDeltaYs.length > scrollState.maxRecentDeltaYs) {
    scrollState.recentDeltaYs.shift()
  }
}

/**
 * Determines if a scroll event should be triggered based on the average deltaY.
 * @param {number} averageDeltaY - The average deltaY of recent scroll events.
 * @returns {boolean} True if the scroll event should be triggered, false otherwise.
 */
const shouldTriggerScroll = (averageDeltaY: number) => {
  return averageDeltaY >= scrollState.previousDeltaYAvarage
}

/**
 * Triggers the scroll event and calls the onScroll callback with the scroll direction.
 * @param {WheelEvent} e - The wheel event.
 * @param {(direction: 'up' | 'down') => void} onScroll - The callback function to be called on scroll.
 * @param {number} averageDeltaY - The average deltaY of recent scroll events.
 */
const triggerScrollEvent = (
  e: WheelEvent,
  onScroll: (direction: DirectionType) => void,
  averageDeltaY: number
) => {
  clearTimeout(scrollState.trendTimeout)
  scrollState.previousDeltaYAvarage = averageDeltaY

  if (scrollState.scrollTrend !== 'up') {
    const direction: DirectionType = e.deltaY > 0 ? 'down' : 'up'
    onScroll(direction)
  }

  scrollState.scrollTrend = 'up'
  scrollState.isScrolling = true

  scrollState.trendTimeout = setTimeout(() => {
    scrollState.isScrolling = false
    scrollState.scrollTrend = 'down'
  }, TREND_TIMEOUT)
}

/**
 * Adjusts the scroll multiplier based on the average deltaY and the scroll count.
 * @param {number} averageDeltaY - The average deltaY of recent scroll events.
 */
const adjustScrollMultiplier = (averageDeltaY: number) => {
  const multiplier =
    scrollState.count > SCROLL_COUNT_THRESHOLD
      ? SCROLL_MULTIPLIER_HIGH
      : SCROLL_MULTIPLIER_LOW
  scrollState.previousDeltaYAvarage = averageDeltaY * multiplier
}

/**
 * Resets the scroll count after a specified timeout.
 */
const resetScrollCount = () => {
  scrollState.countTimeout = setTimeout(() => {
    scrollState.count = 0
  }, SCROLL_DEBOUNCE_TIME)
}
